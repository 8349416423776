(function () {
	//hide boxes by default
	$('#clonebox').hide();
	$('.card-box').hide();
	$('.fileDetails').hide();
	$('#marital-state').hide();


	$('#label-complete').on('click', function (e) {
		$('.product-card.highlighted').each(function (i, obj) {
			$(this).removeClass('highlighted');
		});
		$(this).parent().addClass('highlighted');
		$(this).html('geselecteerd');
		$('#label-direct').html('selecteer');
	})

	$('#label-direct').on('click', function (e) {
		$('.product-card.highlighted').each(function (i, obj) {
			$(this).removeClass('highlighted');
		});
		$(this).parent().addClass('highlighted');
		$(this).html('geselecteerd');
		$('#label-complete').html('selecteer');
	})


		var heights = $(".product-card").map(function() {
				return $(this).height() + 640;
			}).get(),

			maxHeight = Math.max.apply(null, heights);

		$(".product-card").height(maxHeight);

	$('[data-toggle="tooltip"]').tooltip()

	var rowIdx = 0;

	$(document).on('click', '.remove', function (e) {
		let removableRow = $(this).data('removeid');
		$("#rowAdder tr#" + removableRow).remove();
		rowIdx--;
	});

	$('#addChild').on('click', function (e) {
		e.preventDefault();
		$('#rowAdder').append(`<tr class="tr-inputs" id="R${++rowIdx}">
			<td class="row-index text-center">
			<label class="col-sm-12" for="d-mpob">Kind</label>
			<div class="col-sm-12"><span class="form-textfield">${rowIdx}</span></div>
			</td>
			<td>
			<label class="col-sm-12" for="d-mpob">Voornamen</label>
			<div class="col-sm-12">
			<input class="form-control-plaintext" id="c-name" name="child[${rowIdx}][name]" placeholder="Geboorteplaats" type="text">
			</div>
			</td>
			<td>
			<label class="col-sm-12" for="d-mpob">Geboortedatum</label>
			<div class="col-sm-12">
			<input class="form-control-plaintext" id="c-dob" name="child[${rowIdx}][birthdate]" placeholder="Geboorteplaats" type="date">
			</div>
			</td>
			<td>
			<label class="col-sm-12" for="d-mpob">Geboorteplaats</label>
			<div class="col-sm-12">
			<input class="form-control-plaintext" id="c-pob" name="child[${rowIdx}][birthplace]" placeholder="Geboorteplaats" type="text">
			</div>
			</td>
			<td class="text-center">
				<button class="btn btn-danger remove"
						type="button" data-removeid="R${rowIdx}">Remove
				</button>
			</td>
		</tr>`);
	});

	$('select#select-marital').on('change', function () {
		let selectedValue = $(this).val();
		console.log('value', selectedValue);
		if (selectedValue === 'ongehuwd' || selectedValue === 'Ongehuwd') {
			$('#marital-state').hide();
		} else {
			$('#marital-state').show();
			$('#selected-marital-state').html(selectedValue);
		}

	})


	// $('a#checkfirstform').on('click', function (evt) {
	// 	alertList = '';
	// 	let returnState = false;
	// 	$("#cptarget1 input").each(function () {
	// 		// your code here.
	// 		if ($(this).attr('placeholder') !== 'Toevoeging') {
	// 			if ($(this).val() === '') {
	// 				alertList += $(this).attr('placeholder') + ', ';
	// 				$(this).addClass('input-border-danger');
	// 				returnState = false;
	// 			} else {
	// 				$(this).removeClass('input-border-danger');
	// 				returnState = true;
	// 			}
	// 		}
	// 	});
	// 	if (returnState === false) {
	// 		let message = alertList.replace(/,\s*$/, "");
	// 		$('.card-box #msg').html('<p class="tiny-text m-0 p-0" style="font-weight: 400">De velden ' + message + ' zijn nog leeg </p>')
	// 		$('.card-box').fadeIn('slow').delay(4000).fadeOut('slow');
	// 		$('.card-box msg').html(' ');
	// 	}
	// 	return returnState;
	// })

	$('input#option1').change(function () {
		if ($(this).is(':checked')) {
			cloneDeceasedAddress(true)
		} else {
			cloneDeceasedAddress(false)
		}
	})

	$('input#option2').change(function () {
		if ($(this).is(':checked')) {
			cloneDeceasedAddress(false)
		} else {
			cloneDeceasedAddress(true)
		}
	})

	$('select#urn-release-address').on('change', function (e) {
		//console.log($(this).val())
		if ($(this).val() === 'cp1-address') {
			cloneAddress(true)
		} else {
			cloneAddress(false)
		}
	})

	$('#pacemaker_price').html(prices.pacemaker_price);
	$('#combined_price').html(prices.combination_price);
	$('#broader_price').html(prices.broad_price);
	$('#longer_price').html(prices.long_price);
	$('#lastride_price').html(prices.lastride_price);
	$('#direct_price').html(prices.direct_price);
	$('#complete_price').html(prices.complete_price);

	$('input#option10').on('change', function () {
		if ($('input#option10').is(':checked')) {
			$('#clonebox').fadeOut('slow');
			$('#urn-release-address option:first').prop('selected', true).trigger("change");
			cloneAddress(false)
		}
	})

	$('input#option9').on('change', function () {
		if ($('input#option9').is(':checked')) {
			$('#clonebox').show();
			cloneAddress(false)
		}
	})

})(window, document)

function cloneAddress(state) {
	let clonedfirstname,
		clonedplace,
		clonedstreetname,
		clonedhousenumber,
		clonedextension,
		clonedzipcode,
		clonedlastname;
	if (state) {
		clonedfirstname = $('#cp1-firstname').val();
		clonedlastname = $('#cp1-lastname').val();
		clonedhousenumber = $('#cp1-housenumber').val();
		clonedextension = $('#cp1-extension').val();
		clonedzipcode = $('#cp1-zipcode').val();
		clonedstreetname = $('#cp1-streetname').val();
		clonedplace = $('#cp1-place').val();
	} else {
		clonedfirstname = '';
		clonedlastname = '';
		clonedhousenumber = '';
		clonedextension = '';
		clonedzipcode = '';
		clonedstreetname = '';
		clonedplace = '';
	}

	//--------------------------------------//
	$('#urn-firstname').val(clonedfirstname);
	$('#urn-lastname').val(clonedlastname);
	$('#urn-streetname').val(clonedstreetname);
	$('#urn-housenumber').val(clonedhousenumber);
	$('#urn-extension').val(clonedextension);
	$('#urn-zipcode').val(clonedzipcode);
	$('#urn-place').val(clonedplace);
}

function cloneDeceasedAddress(state) {
	let clonedplace,
		clonedstreetname,
		clonedhousenumber,
		clonedextension,
		clonedzipcode;
	if (state) {
		clonedhousenumber = $('#d-housenumber').val();
		//console.log('number', clonedhousenumber)
		clonedextension = $('#d-extension').val();
		clonedzipcode = $('#d-zipcode').val();
		clonedstreetname = $('#d-streetname').val();
		clonedplace = $('#d-place').val();
	} else {
		clonedfirstname = '';
		clonedlastname = '';
		clonedhousenumber = '';
		clonedextension = '';
		clonedzipcode = '';
		clonedstreetname = '';
		clonedplace = '';
	}

	//--------------------------------------//
	$('#dr-streetname').val(clonedstreetname);
	$('#dr-housenumber').val(clonedhousenumber);
	$('#dr-extension').val(clonedextension);
	$('#dr-zipcode').val(clonedzipcode);
	$('#dr-place').val(clonedplace);
}

function loadFile(event) {
	let filetype = '';
	let filename = '';
	$('#filename').html(event.target.files[0].name)
	$('#filetype').html(event.target.files[0].type);
	$('.fileDetails').fadeIn('slow');
}


$(document).ready(function () {
	// fix this to return to first tab.
	$('a#first-tab-form').click(function(event){
		event.preventDefault();
		console.log('return to first tab');
		$('a#v-pills-home-tab').click();
	})

	const button = $("button#submitter"),
		next = $('.btnNext'),
		prev = $('.btnPrevious');


	button.on('click',
		function (e) {
			e.preventDefault();
			button.html("*** Bezig met opslaan en verzenden ***");
			button.attr("disabled", "disabled");
			$("#nform").submit();
		});

	next.click(function () {
		//console.log('next', $('.nav-pills .active').parent().next('a'))
		$('.nav-pills .active').parent().next('li').find('a').trigger('click');
		$('html, body').animate({
			scrollTop: $("#topMarkScrollTo").offset().top - 60
		}, 2000);
	});

	prev.click(function () {
		//console.log('previous', $('.nav-pills .active').parent().prev('a'))
		$('.nav-pills .active').parent().prev('li').find('a').trigger('click');
		$('html, body').animate({
			scrollTop: $("#topMarkScrollTo").offset().top - 60
		}, 2000);
	});
	$('input[name=cp1-gender]').change(function () {
		var inputVal = $('input[name=cp1-gender]:checked').val();
		if (inputVal === 'male') {
			$('label#male').addClass('active');
			$('label#female').removeClass('active');
			$('label#neutral').removeClass('active');
		}
		if (inputVal === 'female') {
			$('label#male').removeClass('active');
			$('label#female').addClass('active');
			$('label#neutral').removeClass('active');
		}
		if (inputVal === 'neutral') {
			$('label#male').removeClass('active');
			$('label#female').removeClass('active');
			$('label#neutral').addClass('active');
		}
	});
});

function functionAlert(msg, myYes) {
	var confirmBox = $("#confirm");
	confirmBox.find(".message").text(msg);
	confirmBox.find(".yes").unbind().click(function() {
		confirmBox.hide();
	});
	confirmBox.find(".yes").click(myYes);
	confirmBox.show();
}

function controlInput(target) {
	if (target.value.length < 1) {
		//red border
		target.style.borderBottomColor = "#e74c3c";
		functionAlert();
	} else {
		target.style.borderBottomColor = "#C7AC9C";
	}
}

(function(){
	$("#cp1-email-address, #cp1-telephone").bind("change keyup",
		function () {
			if ($("#cp1-email-address").val() !== '' && $("#cp1-telephone").val() !== "") {
				$(this).closest("form").find(":submit").removeAttr("disabled");
				$('#no-send-msg').hide();
			}else{
				$(this).closest("form").find(":submit").attr("disabled", "disabled");
				$('#no-send-msg').show();
			}
	});
})();

